<template>
	<ViewLayout className="cistern">
		<template v-slot:body>
			<h1>{{ $store.strings.cistern_title }}</h1>
			<p v-if="$store.strings.cistern_intro">{{ $store.strings.cistern_intro }}</p>
			<MediaBox v-if="$store.strings.cistern_tutorial" v-bind:content="$store.strings.cistern_tutorial" />
		</template>
		<template v-slot:footer>
			<div class="uk-button-group">
				<button class="uk-button uk-button-primary uk-button-large matomo-button-no blue-action-button" onclick="_paq.push(['trackEvent', 'has_cistern', false]);" @click="next(false)">
					{{ $store.strings.general_no }}
				</button>
				<button class="uk-button uk-button-primary uk-button-large matomo-button-yes blue-action-button" onclick="_paq.push(['trackEvent', 'has_cistern', true]);" @click="next(true)">
					{{ $store.strings.general_yes }}
				</button>
			</div>
			<router-link to="/" class="go-back-action-button" onclick="_paq.push(['trackEvent', 'has_cistern', 'back']);">
				{{ $store.strings.general_back }}
			</router-link>
		</template>
	</ViewLayout>
</template>

<script>
import ViewLayout from '@/components/ViewLayout.vue'
import MediaBox from '@/components/MediaBox.vue'
export default {
	name: 'HowTo',
	components: {
		ViewLayout,
		MediaBox,
	},
	methods: {
		next(hasCistern) {
			this.$store.request.options.tank = hasCistern
			this.$router.push('howto')
		}
	},
}

// this.$store.image
</script>
<style lang="scss" scoped>
.uk-button-group {
	.uk-button {
		min-width: 100px;
		text-align: center;
	}
}
</style>
